import React, { lazy, Suspense } from 'react';
import { emporiModule } from '@empori/util';
import { render } from './util/render';
import { LoadingSkeleton } from './components/skeletons';
import { SearchOptions } from './search/search';
import { EpcTranslations } from './util/epc';

const Search = lazy(() =>
  import(/* webpackChunkName: "search" */'./search/search'));

emporiModule('primeflowLoaded', {
  primeflow: {
    InitSearch(element: string, searchOptions: SearchOptions) {
      render(
        <Suspense fallback={<LoadingSkeleton />}>
          <Search searchOptions={searchOptions} />
        </Suspense>, document.querySelector(element)
      );
    },
    InitEpc(iframeEl: HTMLElement, email: string, messageOrigin: string, translations: EpcTranslations) {
      import(/* webpackChunkName: "primeflow-epc" */'./util/epc')
        .then(fn => fn.default(iframeEl, email, messageOrigin, translations));
    },
    InitPartstream() {
      import(/* webpackChunkName: "primeflow-partstream" */'./util/partstream')
        .then(fn => fn.default());
    },
    InitClassicSearchUi(trigger: HTMLElement, searchBar: HTMLElement) {
      import(/* webpackChunkName: "primeflow-classic-search" */'./search/classicSearch')
        .then(fn => fn.default(trigger, searchBar));
    }
  }
});